import { Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { typesOfStatistic } from '../filters/mePosFilters/TypesFilter';
import useRequestLocation from '../../../hooks/useRequestLocation';
import moment from 'moment/moment';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ExportCsvMePos from './ExportCsvMePos';

const ShortStatistic = () => {
  const { t } = useTranslation();
  const {
    statisticType,
    startData,
    endData,
    service,
    master,
    haircutType,
    filters,
    isStatisticData,
    isArchivedSelectedCategory
  } = useSelector((state) => ({
    statisticType: state.filters.statisticType,
    startData: state.filters.periodStart,
    endData: state.filters.periodEnd,
    service: state.filters.service,
    master: state.filters.master,
    filters: state.filters,
    haircutType: state.filters.haircutType,
    isStatisticData: state.mePosData.isStatisticData,
    isArchivedSelectedCategory: state.mePosData.isArchivedSelectedCategory
  }));
  const [branch, setBranch] = useState({})
  const [records, setRecords] = useState({})
  const periodToStart = startData !== null
    ? moment(startData).locale('en').format('YYYY-MM-DD')
    : moment().subtract(1, 'week').format('YYYY-MM-DD')
  const periodToEnd = endData !== null
    ? moment(endData).locale('en').format('YYYY-MM-DD')
    : moment().format('YYYY-MM-DD')

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const urlParam = params.get('url');

  const {getFilial, getRecordsSum} = useRequestLocation()
  useEffect(() => {
    getFilial(periodToStart, periodToEnd)
      .then(data => {
        setBranch({
          city: data.branchCity,
          name: data.branchName,
          code: data.currency
        });
      });
  }, [urlParam])

  useEffect(() => {
    getRecordsSum(
      periodToEnd,
      periodToStart,
      master,
      haircutType,
      service
    ).then((data) => {
      const completedData = data.find((el) => el.key === 6);
      const withoutCompletedData = data.find((el) => el.key === 7);
      const fullData = {
        total: data.reduce((acc, el) => el.doc_count + acc, 0)
      };
      const completedSum =
        (completedData ? completedData.doc_count : 0) +
        (withoutCompletedData ? withoutCompletedData.doc_count : 0);
      if (!completedSum) {
        fullData.completedSum = 0;
        fullData.fullPriceSum = 0;
        fullData.maxPrice = 0;
        fullData.minPrice = 0;
        fullData.avgPrice = 0;
      } else {
        fullData.completedSum = completedSum;
        const numList = data
          .map((status) => {
            if (status.key === 6 || status.key === 7) {
              return status.price.buckets.map((price) => {
                return [price.doc_count, price.key];
              }, 0);
            }
            return 0;
          })
          .filter((el) => el !== 0)
          .flat(1);
        fullData.fullPriceSum = numList.reduce(
          (acc, num) => acc + num[0] * num[1],
          0
        );
        fullData.maxPrice = Math.max(...numList.map((el) => el[1]));
        fullData.minPrice = Math.min(...numList.map((el) => el[1]));
        fullData.avgPrice =
          numList.reduce((acc, el) => el[1] * el[0] + acc, 0) / completedSum;
      }
      if (data.find((el) => el.key === 4)) {
        fullData.hasntArrivedSum = data.find((el) => el.key === 4).doc_count;
      } else {
        fullData.hasntArrivedSum = 0;
      }
      if (data.find((el) => el.key === 5)) {
        fullData.canceledSum = data.find((el) => el.key === 5).doc_count;
      } else {
        fullData.canceledSum = 0;
      }
      setRecords(fullData);
    });
  }, [filters, master, haircutType])
  return (
    <div className='overflow-auto mx-4 mx-lg-0 scrollbar'>


        <Col xs={12} style={{ backgroundColor: '#EEECF9', minWidth: '880px' }}
             className='justify-content-between d-flex p-3 align-items-center'>
          <div >
            <div>{t('header.title')}</div>
            <div className='bold-fonts'>
              {statisticType === 'DAILY_STATISTIC' ? t('statistic.daily') :
                typesOfStatistic.find((item) => item.key === statisticType)?.label}

            </div>
          </div>
          <div >
            <div>{t('statistic.branch')}</div>
            <div className='bold-fonts'>{`"${branch.name}", ${branch.city}`}</div>
          </div>
          <div >
            <div className=''>{t('statistic.dateRange')}</div>
            <div className='bold-fonts'>
              {startData !== null
                ? moment(startData).format('DD.MM.YY')
                : moment().subtract(1, 'week').format('DD.MM.YY')}
              {' — '}
              {endData !== null
                ? moment(endData).format('DD.MM.YY')
                : moment().format('DD.MM.YY')}
            </div>
          </div>
          <div >
            <div className=''>{t('statistic.master')}</div>
            <div className='bold-fonts'>
              {master === 'all' ? t('filters.allMasters') : `${master.surname} ${master.name}`}
            </div>
          </div>
          <div >
            <div className=''>{t('statistic.subcategoryCategory')}</div>
            <div className='bold-fonts'>
              {haircutType === 'all' ? t('filters.allCategories') : `${haircutType}${
                  isArchivedSelectedCategory ? ' ' + t('filters.archive') : ''
                }`}
            </div>
          </div>
         {isStatisticData && <ExportCsvMePos />} 
        </Col>
        <Col xs={12} lg={12} style={{ backgroundColor: '#F9F8FD', minWidth: '880px' }}
             className='align-items-center justify-content-between d-flex p-3 gap-3'>
          <div className='bold-fonts col-2'>{t('statistic.summary')}</div>
          <div>
            <div>{t('statistic.totalRecords')}</div>
            <div className='bold-fonts'>{records.total}</div>
          </div>
          {statisticType !== 'POPULAR_SERVICE' && statisticType !== 'POPULAR_MASTER' && (<>
            <div>
              <div>{t('statistic.executedRecords')}</div>
              <div className='d-flex'>
                <div className='me-1 bold-fonts'>{records.completedSum}</div>
                <div
                  className='opacity-50 font-16'>{`(${records.total ? Number((records.completedSum / records.total * 100).toFixed(1)) : 0}%)`}</div>
              </div>
            </div>
            <div>
              <div>{t('statistic.canceledRecords')}</div>
              <div className='d-flex'>
                <div className='me-1 bold-fonts'>{records.canceledSum}</div>
                <div
                  className='opacity-50 font-16'>{`(${records.total ? Number((records.canceledSum / records.total * 100).toFixed(1)) : 0}%)`}</div>
              </div>
            </div>
            <div>
              <div>{t('statistic.clientNotCome')}</div>
              <div className='d-flex'>
                <div className='me-1 bold-fonts'>{records.hasntArrivedSum}</div>
                <div className='opacity-50 font-16'>
                  {`(${records.total ? Number((records.hasntArrivedSum / records.total * 100).toFixed(1)) : 0}%)`}
                </div>
              </div>
            </div>
          </>)}
          {statisticType !== 'COMPLETE_MASTER' && statisticType !== 'COMPLETE_SERVICE' && (<>
            <div className="pe-4">
              <div className='pe-1'>{t('statistic.income')}</div>
              <div className='bold-fonts'>{records.fullPriceSum} {branch.code}</div>
            </div>
            <div>
              <div>{t('statistic.maxPrice')}</div>
              <div className='bold-fonts'>{records.maxPrice} {branch.code}</div>
            </div>
            <div>
              <div>{t('statistic.minPrice')}</div>
              <div className='bold-fonts'>{records.minPrice} {branch.code}</div>
            </div>
            <div>
              <div>{t('statistic.averagePrice')}</div>
              <div className='bold-fonts'>{records.avgPrice ? records.avgPrice.toFixed(2) : 0} {branch.code}</div>
            </div>
          </>)}
        </Col>
    </div>
)
}

export default ShortStatistic