import Dropdown from 'react-bootstrap/Dropdown';
import { DropField } from '../../../elements/general';
import { useEffect, useState } from 'react';
import useRequestLocation from '../../../../hooks/useRequestLocation';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { CategoryPickerComponent } from '@me-pos/category-picker';
import { BsChevronUp } from 'react-icons/bs';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCategoryLevel,
  setIsArchivedSelectedCategoryl
} from '../../../../redux/slices/mePosData';

const HaircutSelectFilter = ({
  endData,
  startData,
  selectedMaster,
  setHairstyles,
  setService,
  selectedHairstyle
}) => {
  const isArchivedSelectedCategory = useSelector(
    (state) => state.mePosData.isArchivedSelectedCategory
  );
  const [hairstylesList, setHairstylesList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const { getHairstyles } = useRequestLocation();

  const dispatch = useDispatch();

  useEffect(() => {
    const periodToStart =
      startData !== null
        ? moment(startData).locale('en').format('YYYY-MM-DD')
        : moment().subtract(1, 'week').format('YYYY-MM-DD');
    const periodToEnd =
      endData !== null
        ? moment(endData).locale('en').format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD');
    getHairstyles(periodToEnd, periodToStart, selectedMaster).then(
      (countHairstyles) => {
        const categoriesWithSubcategories = countHairstyles.map((item) => ({
          id: item.parentCategoryId.buckets[0].key,
          name: `${item.key}${
            !!item.parentCategoryDeleted.buckets[0].key
              ? ` ${t('filters.archive')}`
              : ''
          }`,
          nameOriginal: item.key,
          isDeleted: !!item.parentCategoryDeleted.buckets[0].key,
          subcategoryList: item.subcategories.buckets.map((el) => ({
            id: el.subcategoryId.buckets[0].key,
            name: `${el.key}${
              !!el.subcategoryDeleted.buckets[0].key
                ? ` ${t('filters.archive')}`
                : ''
            }`,
            nameOriginal: el.key,
            isDeleted: !!el.subcategoryDeleted.buckets[0].key
          }))
        }));
        setHairstylesList(categoriesWithSubcategories);
      }
    );
    // eslint-disable-next-line
  }, [startData, endData, selectedMaster]);

  const handleChangeCategory = (e) => {
    dispatch(setIsArchivedSelectedCategoryl(e.isPicked ? e.isDeleted : false));
    dispatch(setCategoryLevel(e.isPicked ? 'parent' : ''));
    setHairstyles(e.isPicked ? e.nameOriginal : 'all');
    setIsOpen(false);
  };

  const handleChangeSubcategory = (e) => {
    dispatch(setIsArchivedSelectedCategoryl(e.isPicked ? e.isDeleted : false));
    dispatch(setCategoryLevel(e.isPicked ? 'sub' : ''));
    setHairstyles(e.isPicked ? e.nameOriginal : 'all');
    setIsOpen(false);
  };

  return (
    <>
      <div
        className="position-relative rounded-1"
        style={{ marginBottom: isOpen ? 51 : undefined }}
      >
        <div
          className="position-absolute"
          style={{ top: 14, right: 10, zIndex: isOpen ? 2 : -1 }}
          onClick={() => setIsOpen(false)}
        >
          <BsChevronUp />
        </div>
        <div
          className={`position-absolute bg-white w-100 rounded-1 ${isOpen ? "border" : "border-0"}`}
          style={{ zIndex: 1 }}
        >
          <CategoryPicker
            style={isOpen ? { display: 'block' } : { display: 'none' }}
          >
            <CategoryPickerComponent
              serviceCategories={hairstylesList}
              onSearchChange={(e) => {}}
              onChangeCategory={handleChangeCategory}
              onChangeSubcategory={handleChangeSubcategory}
              locale={i18n.language}
              isOpen={true}
            />
          </CategoryPicker>
        </div>
      </div>
      {!isOpen && (
        <DropField
          className="custom-dropdown py-2 rounder-4px border overflow-hidden"
          onClick={() => setIsOpen(true)}
        >
          <Dropdown.Toggle className="form-select overflow-hidden">
            <span className="col-11 text-start text-truncate pe-2">
              {selectedHairstyle === 'all'
                ? t('filters.allCategories')
                : `${selectedHairstyle}${
                  isArchivedSelectedCategory ? ' ' + t('filters.archive') : ''
                }`}
            </span>
          </Dropdown.Toggle>
        </DropField>
      )}
    </>
  );
};

export default HaircutSelectFilter;

const CategoryPicker = styled.div`
    input {
        padding-top: 12px;
        padding-bottom: 12px;
        border-radius: 4px;
        border: none;
        border-bottom: 1px solid #DEDEDE;
    }

    input::placeholder {
        font-family: "Arial", sans-serif;
        font-size: 14px;
    }

    input:focus {
        border-bottom: transparent;
        box-shadow: 0 0 0 .25rem rgb(88, 66, 190, 0.25);
        color: #1F3354;
  }
`;