import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { ColorLabel } from '../../elements/general';
import LineChart from '../../charts/LineChart';
import { MONTH, YEAR, DAY, WEEK } from '../../../constants';
import useRequestLocation from '../../../hooks/useRequestLocation';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { setIsExportCsvActive } from '../../../redux/slices/meQrData';
import useUrlParams from '../../../hooks/useUrlParams';

export const getFormatedLabel = (dateStr, period) => {
  if (period === MONTH) {
    return moment(dateStr).format('MMM YYYY');
  } else if (period === YEAR) {
    return moment(dateStr).format('YYYY');
  } else if (period === DAY) {
    return moment(dateStr).format('DD MMM');
  } else if (period === WEEK) {
    return moment(dateStr).format('MMM DD, YYYY');
  } else {
    return null;
  }
};

const TotalUsersChart = () => {
  const {
    isFitersActive,
    periodStart,
    periodEnd,
    filters,
    secondPeriodStart,
    secondPeriodEnd,
    isCompareMode,
    page
  } = useSelector((state) => ({
    isFitersActive: state.filters.isFitersActive,
    periodStart: state.filters.periodStart,
    periodEnd: state.filters.periodEnd,
    filters: state.filters,
    secondPeriodStart: state.filters.secondPeriodStart,
    secondPeriodEnd: state.filters.secondPeriodEnd,
    isCompareMode: state.filters.isCompareMode,
    page: state.meQrData.pagination.page
  }));
  const { t } = useTranslation();
  const { isMePage } = useUrlParams();

  const [activeButton, setActiveButton] = useState(null);
  const [labels, setLabels] = useState([]);
  const [dataCounts, setDataCounts] = useState([]);
  const [total, setTotal] = useState(0);
  const [dataCounts2, setDataCounts2] = useState([]);
  const [total2, setTotal2] = useState(0);

  const { getFilteredTotalUsers } = useRequestLocation();
  const dispatch = useDispatch();

  const updateChartData = (countDates) => {
    setLabels(
      countDates.map((item) =>
        getFormatedLabel(item.key_as_string, activeButton)
      )
    );
    setDataCounts(countDates.map((item) => item.doc_count));
    setTotal(countDates.reduce((acc, item) => acc + item.doc_count, 0));
  };

  const updateChartCompare = (countDates, countDates2) => {
    setTotal2(countDates2.reduce((acc, item) => acc + item.doc_count, 0));
    setTotal(countDates.reduce((acc, item) => acc + item.doc_count, 0));
    const start = countDates[0]?.key_as_string;
    const bigerDateArr =
      countDates.length > countDates2.length ? countDates : countDates2;
    const dates = [];
    for (
      let date = moment(start);
      date.isBefore(moment(start).add(bigerDateArr.length, activeButton));
      date.add(1, activeButton)
    ) {
      dates.push(date.toISOString());
    }
    setLabels(
      dates.map((item) => getFormatedLabel(item.key_as_string, activeButton))
    );
    setDataCounts(
      dates.map(
        (item) =>
          countDates.find((el) => item === el.key_as_string)?.doc_count || 0
      )
    );
    setDataCounts2(dates.map((_, index) => countDates2[index]?.doc_count || 0));
  };

  useEffect(() => {
    if (!activeButton) return;
    getFilteredTotalUsers({
      startDate: periodStart,
      endDate: periodEnd,
      period: activeButton
    }).then((countDates) => {
      if (secondPeriodStart && secondPeriodEnd) {
        getFilteredTotalUsers({
          startDate: secondPeriodStart,
          endDate: secondPeriodEnd,
          period: activeButton
        }).then((countDates2) => {
          updateChartCompare(countDates, countDates2);
        });
      } else {
        updateChartData(countDates);
        setDataCounts2([]);
      }
    });
    // eslint-disable-next-line
  }, [activeButton, isFitersActive, filters, page]);

  useEffect(() => {
    if (isFitersActive) {
      setActiveButton(isMePage ? MONTH : WEEK);
    } else {
      setActiveButton(MONTH);
    }
  }, [isFitersActive, isMePage]);

  useEffect(() => {
    dispatch(setIsExportCsvActive(!isCompareMode && total > 0));
  }, [isCompareMode, total, dispatch]);

  const totalText = isMePage ? t('scans.users') : t('scans.total');

  return (
    <Col xs={12} lg={6}>
      <div className="p-3 p-lg-4 bg-white h-100 d-flex flex-column justify-content-between">
        <Row className="justify-content-between">
          <Col xs={12} lg="auto">
            <div className="fw-bold fs-4 mb-2 text-capitalize">
              {t('scans.users')}
            </div>
          </Col>
          <Col xs={12} lg="auto">
            {!isFitersActive && (
              <ButtonGroup className="w-100 w-lg-auto">
                {!isMePage && (
                  <Button
                    variant="outline-lightGray"
                    active={activeButton === YEAR}
                    onClick={() => setActiveButton(YEAR)}
                  >
                    {t('scans.year')}
                  </Button>
                )}
                <Button
                  variant="outline-lightGray"
                  active={activeButton === MONTH}
                  onClick={() => setActiveButton(MONTH)}
                >
                  {t('scans.month')}
                </Button>
                <Button
                  variant="outline-lightGray"
                  active={activeButton === DAY}
                  onClick={() => setActiveButton(DAY)}
                >
                  {t('scans.day')}
                </Button>
              </ButtonGroup>
            )}
            {isFitersActive && (
              <ButtonGroup className="w-100 w-lg-auto">
                {isMePage ? (
                  <Button
                    variant="outline-lightGray"
                    active={activeButton === MONTH}
                    onClick={() => setActiveButton(MONTH)}
                  >
                    {t('scans.month')}
                  </Button>
                ) : (
                  <Button
                    variant="outline-lightGray"
                    active={activeButton === WEEK}
                    onClick={() => setActiveButton(WEEK)}
                  >
                    {t('scans.week')}
                  </Button>
                )}
                <Button
                  variant="outline-lightGray"
                  active={activeButton === DAY}
                  onClick={() => setActiveButton(DAY)}
                >
                  {t('scans.day')}
                </Button>
              </ButtonGroup>
            )}
          </Col>
        </Row>
        <Row className="justify-content-center">
          <LineChart
            labels={labels}
            dataCounts={dataCounts}
            dataCounts2={dataCounts2}
            color={isMePage ? '#3A83F6' : '#9B27AF'}
            color2={isMePage ? '#94BDFF' : '#DA94E6'}
            preLable={t('scans.total')}
          />
          {!isCompareMode ? (
            <Col
              xs={12}
              className="text-gray d-flex justify-content-center align-items-center"
            >
              <ColorLabel color={`${isMePage ? '#3A83F6' : '#9b27af'}`} />
              {totalText} (
              {total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')})
            </Col>
          ) : (
            <>
              <Row className="justify-content-center gx-2">
                <Col
                  xs="auto"
                  className="text-gray d-flex justify-content-center align-items-center"
                >
                  {t('devices.previous')}:
                </Col>
                <Col
                  xs="auto"
                  className="text-gray d-flex justify-content-center align-items-center"
                >
                  <ColorLabel color={isMePage ? '#94BDFF' : '#DA94E6'} />
                  {t('scans.total')} (
                  {total2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')})
                </Col>
              </Row>
              <Row className="justify-content-center gx-2">
                <Col
                  xs="auto"
                  className="text-gray d-flex justify-content-center align-items-center"
                >
                  {t('devices.current')}:
                </Col>
                <Col
                  xs="auto"
                  className="text-gray d-flex justify-content-center align-items-center"
                >
                  <ColorLabel color={isMePage ? '#3A83F6' : '#9B27AF'} />
                  {t('scans.total')} (
                  {total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')})
                </Col>
              </Row>
            </>
          )}
        </Row>
      </div>
    </Col>
  );
};

export default TotalUsersChart;
