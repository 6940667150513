import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ColorLabel } from '../../elements/general';
import { DAY } from '../../../constants';
import useRequestLocation from '../../../hooks/useRequestLocation';
import { useSelector } from 'react-redux';
import { getFormatedLabel } from '../totalUsersChart';
import moment from 'moment';
import LineChart from '../../charts/LineChart';
import { useTranslation } from 'react-i18next';

const RevenueTrade = () => {
  const { t } = useTranslation();
  const {
    isFitersActive,
    periodStart,
    periodEnd,
    filters,
    master,
    haircutType,
    service
  } = useSelector((state) => ({
    isFitersActive: state.filters.isFitersActive,
    periodStart: state.filters.periodStart,
    periodEnd: state.filters.periodEnd,
    filters: state.filters,
    master: state.filters.master,
    haircutType: state.filters.haircutType,
    service: state.filters.service
  }));

  const [activeButton, setActiveButton] = useState(DAY);
  const [labels, setLabels] = useState([]);
  const [dataCounts, setDataCounts] = useState([]);
  const [dataCounts2, setDataCounts2] = useState([]);

  const { getRevenueTrade } = useRequestLocation();


  useEffect(() => {
    const periodToStart = periodStart !== null
      ? moment(periodStart).locale('en').format('YYYY-MM-DD')
      : moment().subtract(1, 'week').format('YYYY-MM-DD')
    const periodToEnd = periodEnd !== null
      ? moment(periodEnd).locale('en').format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD')
    getRevenueTrade(periodToEnd, periodToStart, master, haircutType, service).then((prices) => {
      const revenueArray = prices.map((el) => {
        return el.price.buckets.reduce((acc, el) => el.key * el.doc_count + acc, 0)
      })
      setDataCounts(revenueArray);

      const avgNumber = Number((revenueArray.reduce((acc, el) => acc+el, 0) / revenueArray.length).toFixed(2))
      const difference = (avgNumber - revenueArray[0]) / (revenueArray.length-1)
      const arrayNumbers = [revenueArray[0]]
      for (let i = 2; i < revenueArray.length; i++) {
        const number = (revenueArray[0] + difference * (i-1)).toFixed(2)
        arrayNumbers.push(number)
      }
      arrayNumbers.push(avgNumber).toFixed(2)
      setDataCounts2(arrayNumbers);
      setLabels(
        prices.map((item) =>
          getFormatedLabel(item.key_as_string, activeButton)
        )
      );
    });
  }, [filters]);

  return (
    <Col xs={12} lg={12}>
      <div className="p-3 p-lg-4 bg-white h-100 d-flex flex-column justify-content-between">
        <Row className="justify-content-between">
          <Col xs={12} lg="auto">
            <h4 className="h4-heads mb-2 text-capitalize">
              {t('statistic.circulation')}
            </h4>
          </Col>
          <Row>
            <Col
              lg={6} xs={12}
              className="text-dark d-flex justify-content-lg-end align-items-center"
            >
              <ColorLabel color='#EEECF9' borderColor='#5842BE' borderRadius='2px'/>
              <p className='mb-0 fs-6'>{t('statistic.income')}</p>
            </Col>
            <Col
              lg={6} xs={12}

              className="text-dark d-flex justify-content-start align-items-center"
            >
              <ColorLabel color='#E8F7F4' borderColor='#1AB394' borderRadius='2px'/>
              <p className='mb-0 fs-6'>{t('statistic.tradeLine')}</p>
            </Col>
          </Row>
        </Row>
        <Row className="justify-content-center">
          <LineChart
            labels={labels}
            dataCounts={dataCounts}
            color="#5842BE"
            dataCounts2={dataCounts2}
            color2="#1AB394"
            additionalOptions={{pointRadius: 5}}
          />
        </Row>
      </div>
    </Col>
  );
};

export default RevenueTrade;
