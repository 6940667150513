import { Col, Row } from 'react-bootstrap';
import { useState } from 'react';
import { useEffect } from 'react';
import useRequestLocation from '../../../hooks/useRequestLocation';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PeriodScans from './PeriodScans';
import DefaultScans from './DefaultScans';
import CompareScans from './CompareScans';
import TotalIcon from './svg/TotalIcon';
import useUrlParams from '../../../hooks/useUrlParams';
import TotalMePageIcon from './svg/TotalMePageIcon';

const TotalScans = () => {
  const [scans, setScans] = useState(0);
  const [firstPeriod, setFirstPeriod] = useState(0);
  const [secondPeriod, setSecondPeriod] = useState(0);

  const { getTotalScans, getFilteredTotalScans } = useRequestLocation();
  const {
    mode,
    periodStart,
    periodEnd,
    secondPeriodStart,
    secondPeriodEnd,
    filters,
    page
  } = useSelector((state) => ({
    mode: state.filters.mode,
    periodStart: state.filters.periodStart,
    periodEnd: state.filters.periodEnd,
    secondPeriodStart: state.filters.secondPeriodStart,
    secondPeriodEnd: state.filters.secondPeriodEnd,
    filters: state.filters,
    page: state.meQrData.pagination.page
  }));
  const { t } = useTranslation();
  const { isMePage } = useUrlParams();

  useEffect(() => {
    let mounted = true;
    getTotalScans().then((data) => {
      if (mounted) {
        setScans(data);
      }
    });
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
  }, [filters.qrIds, page]);

  useEffect(() => {
    if (mode === 'period') {
      getFilteredTotalScans(periodStart, periodEnd).then((data) => {
        setFirstPeriod(data.filteredScans);
      });
    }
    if (mode === 'compare') {
      getFilteredTotalScans(secondPeriodStart, secondPeriodEnd).then((data) =>
        setSecondPeriod(data.filteredScans)
      );
      getFilteredTotalScans(periodStart, periodEnd).then((data) => {
        setFirstPeriod(data.filteredScans);
      });
    }
    // eslint-disable-next-line
  }, [mode, filters, page]);

  const totalScansText = isMePage ? t('mePage.totalScans') : t('scans.total');
  const totalDescriptionText = isMePage
    ? t('mePage.descriptionTotal')
    : t('scans.descriptionTotal');
  const Icon = isMePage ? TotalMePageIcon : TotalIcon;

  return (
    <Col xs={12} lg={6}>
      <div className="p-3 p-lg-4 bg-white h-100">
        <Row className="justify-content-between">
          <Col xs={7}>
            <div className="fw-bold fs-4 mb-2 text-capitalize">
              {totalScansText}
            </div>
            <div className="text-gray fs-7 mb-3 mb-lg-4">
              {totalDescriptionText}
            </div>
          </Col>
          <Col
            xs={3}
            className="d-flex justify-content-end align-items-start mt-4 mt-lg-0"
          >
            <Icon />
          </Col>
        </Row>
        {mode === 'default' && <DefaultScans scans={scans} />}
        {mode === 'period' && <PeriodScans scans={firstPeriod} total={scans} />}
        {mode === 'compare' && (
          <CompareScans firstPeriod={firstPeriod} secondPeriod={secondPeriod} />
        )}
      </div>
    </Col>
  );
};

export default TotalScans;
