import Dropdown from 'react-bootstrap/Dropdown';
import { DropField } from '../../../elements/general';
import { useEffect, useState } from 'react';
import useRequestLocation from '../../../../hooks/useRequestLocation';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';



const MasterSelectFilter = ({ endData, startData, selectedMaster, setSelectedMaster, haircutType, service, handleSetMastersData }) => {
  const [masterList, setMasterList] = useState([])
  const { t } = useTranslation();
  const { getMasters } = useRequestLocation();

  useEffect(() => {
    const periodToStart = startData !== null
      ? moment(startData).locale('en').format('YYYY-MM-DD')
      : moment().subtract(1, 'week').format('YYYY-MM-DD')
    const periodToEnd = endData !== null
      ? moment(endData).locale('en').format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD')
    getMasters(periodToEnd, periodToStart, haircutType, service).then((countMasters) => {
      handleSetMastersData(!countMasters.length);
      setMasterList(countMasters.map(el => {
        return { surname: el.key, name: el.name.buckets[0].key,
          id: `${el.key}_${el.name.buckets[0].key}_${Date.now()}` }
      }));
    });
  }, [startData, endData, haircutType])

  const handleMasterSelect = (item) => {
    setSelectedMaster(item);
  };

  return (
    <DropField className='custom-dropdown py-2 rounder-4px border'>
      <Dropdown.Toggle className="form-select">
          <span className="col-11 text-start text-truncate pe-2">
            {selectedMaster.id ?
              `${selectedMaster.name} ${selectedMaster.surname}`
              :
              t('filters.allMasters')
            }
          </span>
      </Dropdown.Toggle>

      <Dropdown.Menu className='custom-dropdown-menu'>
        <Dropdown.Item onClick={() => handleMasterSelect('all')}
                       className={selectedMaster === 'all' ? 'active' : ''}>
          {t('filters.allMasters')}
        </Dropdown.Item>
        {masterList.map((item) => (
          <Dropdown.Item key={item.id} onClick={() => handleMasterSelect(item)}
                         className={item === selectedMaster ? 'active' : ''}>
            {item.name} {item.surname}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </DropField>
  );
};

export default MasterSelectFilter;
