import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import EN from './constants/languages/en.json';
import RU from './constants/languages/ru.json';
import ES from './constants/languages/es.json';
import PT from './constants/languages/pt.json';
import MS from './constants/languages/ms.json';
import AR from './constants/languages/ar.json';
import DE from './constants/languages/de.json';
import FR from './constants/languages/fr.json';
import ID from './constants/languages/id.json';
import IT from './constants/languages/it.json';
import JA from './constants/languages/ja.json';
import KO from './constants/languages/ko.json';
import NL from './constants/languages/nl.json';
import SV from './constants/languages/sv.json';
import TH from './constants/languages/th.json';
import VI from './constants/languages/vi.json';
import UK from './constants/languages/uk.json';
import ZH from './constants/languages/zh.json';
import HE from './constants/languages/he.json';
import HI from './constants/languages/hi.json';
import PL from './constants/languages/pl.json';
import CS from './constants/languages/cs.json';

const resources = {
  en: {
    translation: EN
  },
  ru: {
    translation: RU
  },
  es: {
    translation: ES
  },
  pt: {
    translation: PT
  },
  ms: {
    translation: MS
  },
  ar: {
    translation: AR
  },
  de: {
    translation: DE
  },
  fr: {
    translation: FR
  },
  id: {
    translation: ID
  },
  it: {
    translation: IT
  },
  ja: {
    translation: JA
  },
  ko: {
    translation: KO
  },
  nl: {
    translation: NL
  },
  sv: {
    translation: SV
  },
  th: {
    translation: TH
  },
  vi: {
    translation: VI
  },
  uk: {
    translation: UK
  },
  zh: {
    translation: ZH
  },
  he: {
    translation: HE
  },
  hi: {
    translation: HI
  },
  pl: {
    translation: PL
  },
  cs: {
    translation: CS
  }
};

i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
