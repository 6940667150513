import { useTranslation } from 'react-i18next';

export default function HideFiltersButton({showFilters, setShowFilters}) {
  const {t} = useTranslation();
  return (
    <button
      className={`btn rounded-1 w-100 btn-outline-primary ${
        showFilters ? 'active' : ''
      }`}
      style={{ padding: '12px 4px' }}
      onClick={() => setShowFilters(!showFilters)}
    >
      <span>
        <i className="bi bi-funnel me-1 position-relative">
            <span className="d-block bg-orange position-absolute end-0 top-0 rounded-circle" style={{width: "5px", height: "5px"}}></span>
        </i> {t('filters.filters')}
      </span>
    </button>
  );
};