import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useRequestLocation from '../../../hooks/useRequestLocation';
import ChartMap from '../../charts/ChartMap';
import useUrlParams from '../../../hooks/useUrlParams';

const CountryMap = () => {
  const { country, cities, filters, page } = useSelector((state) => ({
    country: state.locationScans.country,
    cities: state.locationScans.cities,
    filters: state.filters,
    page: state.meQrData.pagination.page
  }));
  const { getMapData } = useRequestLocation();
  const [mapData, setMapData] = useState([]);
  const { isMePage } = useUrlParams();

  useEffect(() => {
    getMapData().then((data) => {
      const configuredData = data.data.map((item) => {
        return {
          name: `${item.key} : ${((item.doc_count * 100) / data.total).toFixed(
            2
          )}%`,
          latLng: [item.latitude.value, item.longitude.value],
          html: `<div style="background: #3E4957"><strong>Total Scans</strong><br>
        <div class="d-flex align-items-center">
        <div class="bg-${
          isMePage ? 'primary2' : 'primary'
        } me-1" style="width: 12px; height: 12px"></div>
        <span>${item.key} : ${((item.doc_count * 100) / data.total).toFixed(
            2
          )}%</span> 
        </div>`
        };
      });
      setMapData(configuredData);
    });
    // eslint-disable-next-line
  }, [country, cities, filters, page]);

  return <ChartMap mapData={mapData} />;
};

export default CountryMap;
