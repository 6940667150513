import classnames from 'classnames';
import { useSelector } from 'react-redux';
import useUrlParams from '../../hooks/useUrlParams';

const Loader = ({ toggle, opacity }) => {
  const { isPreloader } = useSelector((state) => ({
    isPreloader: state.preloader.toggle
  }));
  const { isMePage } = useUrlParams();

  if (toggle || isPreloader) {
    return (
      <div
        className={classnames(
          'mt-0 position-fixed top-0 start-0 min-vw-100 min-vh-100 d-flex justify-content-center align-items-center',
          {
            [`bg-black  opacity-${opacity}`]: !!opacity,
            'bg-white': !opacity,
            'me-page-loader': isMePage
          }
        )}
        style={{ zIndex: 490 }}
      >
        <div className="loader">
          <svg viewBox="0 0 80 80">
            <rect x="8" y="8" width="64" height="64"></rect>
          </svg>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default Loader;
